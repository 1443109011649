import React, { useState } from 'react'
import { Outlet, redirect, useFetcher } from 'react-router-dom'
import type { ActionFunction } from 'react-router-dom'
import { SearchHistory, SearchHistoryButton } from '../../common/SearchHistory'

export const barcodeSearchAction: ActionFunction = async ({ request }) => {
  const formData = await request.formData()
  const barcode = formData.get('barcode')
  return redirect(`${barcode}`)
}

export const BarcodeSearch = () => {
  const [historyVisible, setHistoryVisible] = useState(false)
  const fetcher = useFetcher()

  return (
    <>
      <div className="max-w-screen-sm mx-auto">
        <h1 className="sr-only">Barcode Search</h1>

        <fetcher.Form method="post" action="" className="relative mt-lg flex items-center space-x-md">
          <SearchHistoryButton active={historyVisible} onClick={() => setHistoryVisible(!historyVisible)} />

          <div className="flex space-x-md w-full">
            <div className="flex-1">
              <input
                aria-label="Search by barcode"
                type="text"
                name="barcode"
                placeholder="Search by barcode"
                className="p-md text-lg flex-1"
              />
            </div>

            <button className="btn btn-primary-dark">Search</button>
          </div>
        </fetcher.Form>

        <SearchHistory show={historyVisible} close={() => setHistoryVisible(false)} />
      </div>

      <Outlet />
    </>
  )
}
