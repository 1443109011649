import React from 'react'
import { Circle } from './Circle'
import { DateWithUTCTooltip } from '../../../common'
import { VoucherTimelineType } from '../../../../types'

type Props = {
  entry: VoucherTimelineType
  locationTimezone?: string
  isFirstRow: boolean
  isLastRow: boolean
}

export const StateRow = ({ entry, locationTimezone, isFirstRow, isLastRow }: Props) => {
  const { eventType, source, createdBy, state, reservedUntil, consumerId, rvmSerialNumber, timestamp } = entry

  const eventTypeToMessageMap: { [key: string]: { [key: string]: string } } = {
    CREATED: {
      RVM: `Created new ${state === 'DIGITAL' ? 'digital' : ''} voucher from RVM (serial: ${createdBy})`,
      RETAIL_ADDED: `Created from retail adding (consumer ID ${createdBy}) in state ${state}`
    },
    STATE_UPDATE: {
      NEW: `Voucher enabled for store payout (state ${state})`,
      DIGITAL: `Voucher set to digital payout (state ${state})`,
      RESERVED: `Reserved by consumer ID ${consumerId} ${reservedUntil ? 'until' : ''}`,
      CONSUMED: `Consumed by consumer ID ${consumerId} `
    },
    SOURCE_UPDATE: {
      RVM: `Retail added voucher replaced by consumer session from RVM ${rvmSerialNumber}`
    }
  }

  const message = eventTypeToMessageMap[eventType][(source as string) || (state as string)]

  return (
    <div className="flex items-center py-md">
      <div className="mr-lg w-[9.375rem]">
        <DateWithUTCTooltip date={timestamp} timeZone={locationTimezone} />
      </div>

      <Circle isFirstRow={isFirstRow} isLastRow={isLastRow} />

      <span className="relative ml-lg">
        {message}
        {reservedUntil && <DateWithUTCTooltip date={reservedUntil} timeZone={locationTimezone} />}
      </span>
    </div>
  )
}

export default StateRow
