import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { TimeIcon } from '../icons'
import { FROM_DATE_SEARCH_PARAM, TO_DATE_SEARCH_PARAM } from '../../constants'
import { getSearchHistory } from '../../lib/history'
import { AnimatePresence, motion } from 'framer-motion'

type SearchHistoryButtonProps = {
  onClick: () => void
  active: boolean
}

export const SearchHistoryButton = ({ onClick, active }: SearchHistoryButtonProps) => {
  const [searchHistory, setSearchHistory] = useState<string[]>([])

  useEffect(() => {
    getSearchHistory().then(setSearchHistory)
  }, [])

  return searchHistory.length > 0 ? (
    <button type="button" onClick={onClick} className="btn btn-icon" aria-label="Show search history">
      <TimeIcon width="2rem" height="2rem" color={active ? 'var(--colors-blue)' : 'var(--colors-black)'} />
    </button>
  ) : null
}

export const SearchHistory = ({ close, show }: { close: Function; show: boolean }) => {
  const [searchHistory, setSearchHistory] = useState<string[]>([])

  useEffect(() => {
    getSearchHistory().then(setSearchHistory)
  }, [])

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="mt-sm whitespace-nowrap"
          onClick={() => close()}
        >
          <div className="flex items-center mt-lg mb-sm">
            <h2 className="mr-sm">History</h2>
            <small>(last 5 searches)</small>
          </div>

          <div className="flex flex-col-reverse">
            {searchHistory.map((url: string) => {
              try {
                const searchParams = new URL(url).searchParams
                const fromMs = parseInt(searchParams.get(FROM_DATE_SEARCH_PARAM) as string)
                const toMs = parseInt(searchParams.get(TO_DATE_SEARCH_PARAM) as string)

                const isBarcode = !fromMs || !toMs

                if (isBarcode) {
                  const [, barcode] = url.split('/barcode/')
                  return (
                    <Link to={url} key={url} className="my-xs underline underline-offset-2">
                      Barcode {barcode}
                    </Link>
                  )
                }

                // eslint-disable-next-line no-unsafe-optional-chaining
                const [serial] = url.split('/')?.[5].split('?')

                return (
                  <Link to={url} key={url} className="my-xs underline">
                    Serial {serial} between {new Date(fromMs).toLocaleDateString()} and{' '}
                    {new Date(toMs).toLocaleDateString()}
                  </Link>
                )
              } catch (e) {
                /* just render an empty div if we can't parse the URL */
                return <div />
              }
            })}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
