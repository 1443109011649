import React from 'react'
import { VoucherList } from '../../common/VoucherList'
import { API_HOST, authenticatedFetchData, logError } from '../../../lib'
import { VoucherType } from '../../../types'
import { Navigate, useFetchers, useLoaderData, useParams } from 'react-router-dom'
import type { LoaderFunction } from 'react-router-dom'
import { ErrorIcon } from '../../icons'
import { updateSearchHistory } from '../../../lib/history'

export const barcodeResultsLoader: LoaderFunction = async ({ params, request }) => {
  const { barcode } = params

  await updateSearchHistory(request.url)

  const url = `${API_HOST}/v1.0/search/barcode/${barcode}`

  return await authenticatedFetchData(url)
    .run()
    .then(response => ({ vouchers: response }))
    .catch(error => {
      if (error.status !== 500) {
        logError(new Error(`Failed to fetch barcode ${barcode}`), error)
      }

      return { error: 'Failed to fetch vouchers' }
    })
}

export const BarcodeResults = () => {
  const { barcode } = useParams()
  const { vouchers: maybeVouchers, error } = useLoaderData() as { vouchers?: VoucherType[]; error?: string }

  const [fetcher] = useFetchers()

  if (fetcher?.state === 'loading' || fetcher?.state === 'submitting') {
    return (
      <div className="centerFlex my-lg">
        <div className="loadingSpinner" />
      </div>
    )
  }

  if (error) {
    return (
      <div className="alert alert-danger max-w-screen-sm mx-auto my-lg flex items-center space-x-sm">
        <ErrorIcon width="1.5rem" height="1.5rem" color="var(--colors-red)" />
        <div>{error}</div>
      </div>
    )
  }

  if (maybeVouchers?.length === 0) {
    return (
      <div className="alert alert-warning max-w-screen-sm mx-auto my-lg">
        No vouchers found matching barcode {barcode}
      </div>
    )
  }

  const vouchers = maybeVouchers as VoucherType[]

  if (vouchers.length === 1 && !vouchers[0].voucherState.startsWith('ARCHIVED')) {
    return <Navigate to={`/transactions/${vouchers[0].barcode}/${vouchers[0].organizationId}`} />
  }

  return (
    <VoucherList vouchers={vouchers}>
      <h2 className="text-lg">Barcode {barcode}</h2>
    </VoucherList>
  )
}
