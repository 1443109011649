import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useNavigation } from 'react-router-dom'
import { Header } from './components/Header'
import { loggedInUserHasRole } from './lib'
import { AnimatePresence, motion } from 'framer-motion'

export const AppLayout = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const isAllowedToView = loggedInUserHasRole('VOUCHER_CONTROL_ADMIN')

  useEffect(() => {
    if (pathname === '/search') {
      navigate('/search/barcode')
    }
  }, [pathname, navigate])

  return (
    <>
      <Header />

      <AnimatePresence>
        {navigation.state === 'loading' && (
          <motion.div
            initial={{ translateY: '-10%', opacity: 0 }}
            animate={{ translateY: '0%', opacity: 1 }}
            exit={{ translateY: '-10%', opacity: 0 }}
            className="absolute top-[6rem] right-lg bg-white rounded-half shadow-lg p-lg min-w-[20rem] text-center flex items-center space-x-md z-10"
          >
            <div className="loadingSpinner" />
            <div>Please wait...</div>
          </motion.div>
        )}
      </AnimatePresence>

      {isAllowedToView ? (
        <Outlet />
      ) : (
        <div className="alert alert-warning centerAbsolute">You&apos;re not authorized to use this application</div>
      )}
    </>
  )
}
