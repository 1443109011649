import { Dialog } from '../../common/Dialog'
import React, { useEffect, useState } from 'react'
import { OrganizationType } from '../../../types'
import { API_HOST, authenticatedFetchData, logError } from '../../../lib'
import { useFetcher, useLoaderData } from 'react-router-dom'
import type { ActionFunction } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

export const addUserAction: ActionFunction = async ({ request }) => {
  const formData = await request.formData()
  const { userName, organizationId } = Object.fromEntries(formData)

  return authenticatedFetchData(`${API_HOST}/v1.0/soap-clients`, {
    method: 'POST',
    body: JSON.stringify({
      userName,
      organizationId
    })
  })
    .run()
    .then(() => ({ success: 'User created' }))
    .catch(error => {
      if (error.status !== 500) {
        logError(new Error(`Failed to add user`), error)
      }

      return { error: 'Failed to add user' }
    })
}

export const AddNewUserDialog = ({ show, onClose }: { show: boolean; onClose: () => void }) => {
  const { organizations } = useLoaderData() as {
    organizations: OrganizationType[]
  }
  const [organizationSearchResults, setOrganizationSearchResults] = useState<OrganizationType[]>([])
  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationType | null>(null)
  const [organizationSearchTerm, setOrganizationSearchTerm] = useState('')
  const [value] = useDebounce(organizationSearchTerm, 500)

  useEffect(() => {
    if (!value) {
      setOrganizationSearchResults([])
      return
    }

    const termLowerCase = value.toLowerCase()

    const results = organizations.filter(({ name, id }) => {
      const nameLowerCase = name.toLowerCase()
      const idLowerCase = id.toLowerCase()
      return (
        nameLowerCase.startsWith(termLowerCase) ||
        nameLowerCase.includes(termLowerCase) ||
        idLowerCase.startsWith(termLowerCase)
      )
    })

    setOrganizationSearchResults(results)
  }, [value, setOrganizationSearchResults, organizations])

  const fetcher = useFetcher()

  useEffect(() => {
    const handleKeypress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    window.addEventListener('keyup', handleKeypress)
    return () => window.removeEventListener('keyup', handleKeypress)
  }, [onClose])

  useEffect(() => {
    if (fetcher.data?.success && fetcher.state !== 'loading') {
      onClose()
    }
  }, [fetcher, onClose])

  const noResults = organizationSearchTerm.length > 0 && organizationSearchResults.length === 0

  return (
    <Dialog isShown={show}>
      <fetcher.Form
        className="card p-lg flex flex-col space-y-md min-w-screen-sm max-w-screen-sm"
        method="post"
        action="add-user"
      >
        <h1 className="text-xl">Add new user</h1>

        <div>
          <label htmlFor="organizationId" className="label">
            Organization
          </label>
          <input
            id="organization"
            type="hidden"
            name="organizationId"
            defaultValue={selectedOrganization?.id}
            required
          />
          {selectedOrganization ? (
            <div className="flex justify-between">
              <div>
                <div>{selectedOrganization.name}</div>
                <div className="text-sm">{selectedOrganization.id}</div>
              </div>
              <div>
                <button type="button" className="btn" onClick={() => setSelectedOrganization(null)}>
                  Change
                </button>
              </div>
            </div>
          ) : (
            <div className="relative">
              <input
                aria-label="Search for organization"
                className="w-full border border-grey rounded-half p-[0.6rem]"
                name="organizationId"
                placeholder="Start typing name or id..."
                autoComplete="off"
                defaultValue={organizationSearchTerm}
                onChange={({ target }) => setOrganizationSearchTerm(target.value)}
              />

              <div className="py-xs flex items-center justify-between">
                {noResults && <div>No results</div>}
                <div className="text-right text-sm flex-1">
                  {organizationSearchResults.length} / {organizations.length}
                </div>
              </div>

              {organizationSearchResults.length > 0 && (
                <ul className="absolute w-full border border-fog bg-white shadow-lg rounded-half max-h-[20rem] overflow-scroll">
                  {organizationSearchResults.map(organization => (
                    <li key={organization.id}>
                      <button type="button" className="w-full" onClick={() => setSelectedOrganization(organization)}>
                        <div className="text-left py-md px-md transition hover:bg-grey-light">
                          <div>{organization.name}</div>
                          <div className="text-sm">{organization.id}</div>
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>

        <div>
          <label htmlFor="username" className="label">
            Username
          </label>
          <input id="username" type="text" name="userName" placeholder="Username" required />
        </div>

        {fetcher?.data?.error && <div className="alert alert-danger">Failed to create new user</div>}

        <div className="flex space-x-md justify-end">
          <button type="button" className="btn" onClick={onClose}>
            Close
          </button>

          {fetcher?.state === 'loading' || fetcher?.state === 'submitting' ? (
            <div className="loadingSpinner" />
          ) : (
            <button className="btn btn-primary-dark">Submit</button>
          )}
        </div>
      </fetcher.Form>
    </Dialog>
  )
}
