import { getItemFromWebStorage, setItemInWebStorage } from './webStorage'

const inMemoryHistory: string[] = []

export function getSearchHistory() {
  if (inMemoryHistory.length > 0) {
    return Promise.resolve(inMemoryHistory)
  }

  return getItemFromWebStorage('SEARCH_HISTORY').then(history => {
    return history ? history : []
  })
}

export async function updateSearchHistory(url: string) {
  const searchHistory = await getSearchHistory()

  let updatedHistory = [...searchHistory.filter((existingUrl: string) => existingUrl !== url), url]

  if (searchHistory.length > 5) {
    updatedHistory = searchHistory.slice(1, searchHistory.length)
  }

  return setItemInWebStorage('SEARCH_HISTORY', updatedHistory)
}
