import React from 'react'
import { logError, API_HOST, authenticatedFetchData, loggedInUserHasRole } from '../../../lib'
import { Outlet, redirect, useFetcher, useLoaderData, useParams, useSearchParams } from 'react-router-dom'
import { OrganizationType } from '../../../types'
import { eachMonthOfInterval, eachYearOfInterval, endOfYear, format, startOfYear, subYears } from 'date-fns'
import { MONTH_SEARCH_PARAM, YEAR_SEARCH_PARAM } from '../../../constants'
import type { ActionFunction, LoaderFunction } from 'react-router-dom'

const TODAY = new Date()
const MONTHS = eachMonthOfInterval({ start: startOfYear(TODAY), end: endOfYear(TODAY) })
const TEN_YEARS_AGO = subYears(TODAY, 10)
const YEARS = eachYearOfInterval({ start: new Date(TEN_YEARS_AGO), end: TODAY }).reverse()

export const statisticsSearchAction: ActionFunction = async ({ request }) => {
  const formData = await request.formData()
  const { organizationId, year, month } = Object.fromEntries(formData)
  return redirect(`organization/${organizationId}?year=${year}&month=${month}`)
}

export const statisticsLoader: LoaderFunction = () => {
  return authenticatedFetchData(`${API_HOST}/v1.0/organizations`)
    .run()
    .then(organizations => ({ organizations }))
    .catch(error => {
      if (error.status !== 500) {
        logError(new Error(`Failed to fetch barcode organizations`), error)
      }

      return { error: 'Failed to fetch organizations' }
    })
}

export const StatisticsPage = () => {
  const { organizations: maybeOrganizations, error } = useLoaderData() as {
    organizations: OrganizationType[]
    error?: string
  }
  const { organizationId } = useParams()
  const [searchParams] = useSearchParams()
  const selectedYear = searchParams.get(YEAR_SEARCH_PARAM) as string
  const selectedMonth = searchParams.get(MONTH_SEARCH_PARAM) as string

  const fetcher = useFetcher()

  if (!loggedInUserHasRole('VOUCHER_CONTROL_ADMIN_STATISTICS_SEARCH')) {
    return (
      <div className="alert alert-warning max-w-screen-sm mx-auto my-lg">
        You&apos;re not authorized to view this page
      </div>
    )
  }

  if (fetcher?.state === 'loading' || fetcher?.state === 'submitting') {
    return (
      <div className="centerAbsolute">
        <div className="loadingSpinner" />
      </div>
    )
  }

  if (error) {
    return (
      <h1 className="alert alert-danger max-w-screen-sm mx-auto my-lg">
        Whoopsie! Failed fetch available organizations. Please try again.
      </h1>
    )
  }

  if (maybeOrganizations.length === 0) {
    return (
      <div className="alert alert-warning max-w-screen-sm mx-auto my-lg">
        No available organizations to search through.
      </div>
    )
  }

  const organizations = maybeOrganizations as OrganizationType[]

  return (
    <>
      <h1 className="sr-only">Organization statistics</h1>

      <div className="relative card my-lg max-w-screen-2xl mx-auto">
        <div className="p-lg">
          <fetcher.Form className="grid grid-cols-12 gap-md" method="post" action="">
            <div className="col-span-4">
              <label className="label" htmlFor="organization">
                Organization
              </label>
              <select id="organization" className="select" name="organizationId" defaultValue={organizationId}>
                {organizations.map(({ id, name }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-span-2">
              <label className="label" htmlFor="year">
                Year
              </label>
              <select id="year" className="select" name="year" defaultValue={selectedYear}>
                {YEARS.map(year => {
                  const yearInt = format(year, 'yyyy')

                  return (
                    <option key={yearInt} value={yearInt}>
                      {yearInt}
                    </option>
                  )
                })}
              </select>
            </div>

            <div className="col-span-2">
              <label className="label" htmlFor="month">
                Month
              </label>
              <select id="month" className="select" name="month" defaultValue={selectedMonth}>
                {MONTHS.map(month => {
                  const monthInt = format(month, 'M')
                  const monthName = format(month, 'MMMM')

                  return (
                    <option key={monthName} value={monthInt}>
                      {monthName}
                    </option>
                  )
                })}
              </select>
            </div>

            <div className="col-span-4 flex items-center space-x-md self-end">
              <button type="submit" className="btn btn-primary-dark">
                Search
              </button>
            </div>
          </fetcher.Form>
        </div>

        <Outlet />
      </div>
    </>
  )
}
