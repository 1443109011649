import React, { useState, ReactNode, useMemo } from 'react'
import { DateWithUTCTooltip, Filter } from '../common'
import { RightArrowIcon } from '../icons'
import { convertVoucherDomainObjectToReadable } from '../../lib/convertVoucherDomainObjectToReadable'
import { VoucherType } from '../../types'
import { SortableTh } from './SortableTh'
import { sortKeyAscending, sortKeyDescending } from '../../lib/sort'
import { Link } from 'react-router-dom'

const getFilteredVoucherKeys = (vouchers: Array<any>, searchValue: string) => {
  const vouchersAsRenderedInList = vouchers.map(convertVoucherDomainObjectToReadable)

  const filteredVouchersAsRenderedInList = vouchersAsRenderedInList.filter(voucher =>
    JSON.stringify(Object.values(voucher)).toLowerCase().includes(searchValue)
  )
  return filteredVouchersAsRenderedInList.map(({ barcode, organizationId }) => barcode + organizationId)
}

type Props = {
  children: ReactNode
  vouchers: Array<VoucherType>
}

export const VoucherList = ({ children, vouchers }: Props) => {
  const [filteredVoucherKeys, setFilteredVoucherKeys] = useState(getFilteredVoucherKeys(vouchers, ''))
  const [sortKey, setSortKey] = useState<string>('csTimestamp')
  const [sortOrder, setSortOrder] = useState<SortOrderType>('descending')

  const setSortedColumn = (order: SortOrderType, key: string) => {
    setSortOrder(order)
    setSortKey(key)
  }

  const onFilterChange = (newFilterValue: string) => {
    setFilteredVoucherKeys(getFilteredVoucherKeys(vouchers, newFilterValue))
  }

  const sortedVouchers = useMemo(() => {
    return vouchers
      .filter(voucher => filteredVoucherKeys.some(key => key.trim() === voucher.barcode + voucher.organizationId))
      .sort(sortOrder === 'ascending' ? sortKeyAscending(sortKey) : sortKeyDescending(sortKey))
  }, [vouchers, sortOrder, sortKey, filteredVoucherKeys])

  return (
    <div className="card mt-lg">
      <div className="flex p-lg items-center">
        {children}
        <Filter label="Filter results" onChange={onFilterChange} />
      </div>

      <table className="table">
        <caption className="sr-only">Vouchers</caption>
        <thead>
          <tr>
            <SortableTh fieldKey="barcode" onSort={setSortedColumn} sortOrder={sortOrder} sortKey={sortKey}>
              Barcode
            </SortableTh>
            <SortableTh fieldKey="voucherState" onSort={setSortedColumn} sortOrder={sortOrder} sortKey={sortKey}>
              Voucher state
            </SortableTh>
            <SortableTh fieldKey="rvmSerialNumber" onSort={setSortedColumn} sortOrder={sortOrder} sortKey={sortKey}>
              RVM serial
            </SortableTh>
            <SortableTh fieldKey="organizationName" onSort={setSortedColumn} sortOrder={sortOrder} sortKey={sortKey}>
              Organization
            </SortableTh>
            <SortableTh fieldKey="csTimestamp" onSort={setSortedColumn} sortOrder={sortOrder} sortKey={sortKey}>
              CS3 Start time
            </SortableTh>
            <SortableTh fieldKey="csSource" onSort={setSortedColumn} sortOrder={sortOrder} sortKey={sortKey}>
              CS3 Source
            </SortableTh>
            <SortableTh
              fieldKey="transactionTimestamp"
              onSort={setSortedColumn}
              sortOrder={sortOrder}
              sortKey={sortKey}
            >
              Last updated
            </SortableTh>
            <SortableTh fieldKey="consumerId" onSort={setSortedColumn} sortOrder={sortOrder} sortKey={sortKey}>
              Last updated by
            </SortableTh>
            <SortableTh fieldKey="consumerLocation" onSort={setSortedColumn} sortOrder={sortOrder} sortKey={sortKey}>
              Last updated by (sub)
            </SortableTh>
            <SortableTh fieldKey="refundAmount" onSort={setSortedColumn} sortOrder={sortOrder} sortKey={sortKey}>
              Refund amount
            </SortableTh>
            <SortableTh fieldKey="csDigital" onSort={setSortedColumn} sortOrder={sortOrder} sortKey={sortKey}>
              Is digital
            </SortableTh>
          </tr>
        </thead>
        <tbody>
          {sortedVouchers.map(voucher => {
            const {
              barcode,
              organizationId,
              organizationName,
              transactionTimestamp,
              voucherState,
              consumerId,
              consumerLocation,
              refundAmount,
              rvmSerialNumber,
              csTimestamp,
              csSource,
              csDigital,
              locationTimezone
            } = voucher

            return (
              <tr key={barcode + organizationId}>
                <td>{barcode}</td>
                <td>{voucherState}</td>
                <td>{rvmSerialNumber}</td>
                <td>{organizationName}</td>
                <td>
                  <DateWithUTCTooltip date={csTimestamp} timeZone={locationTimezone} />
                </td>
                <td>{csSource}</td>
                <td>
                  <DateWithUTCTooltip date={transactionTimestamp} timeZone={locationTimezone} />
                </td>
                <td>{consumerId}</td>
                <td>{consumerLocation}</td>
                <td>{parseFloat(refundAmount).toFixed(2)}</td>
                <td>{csDigital ? 'Yes' : 'No'}</td>
                <td>
                  {!voucherState.startsWith('ARCHIVED') && (
                    <div className="flex justify-end">
                      <Link
                        to={`/transactions/${barcode}/${organizationId}`}
                        className="btn btn-icon block"
                        aria-label={`Show details for ${barcode}`}
                      >
                        <RightArrowIcon color="var(--colors-blue)" />
                      </Link>
                    </div>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
