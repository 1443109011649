import React from 'react'
import { useLoaderData } from 'react-router-dom'
import type { LoaderFunction } from 'react-router-dom'
import { API_HOST, authenticatedFetchData, logError } from '../../../lib'
import { MONTH_SEARCH_PARAM, YEAR_SEARCH_PARAM } from '../../../constants'
import { StatisticsList } from './StatisticsList'
import { StatisticsType } from '../../../types'
import { CSVLink } from 'react-csv'

const HEADERS = [
  { label: 'Store name', key: 'storeName' },
  { label: 'Rvm serial', key: 'machineSerialNumber' },
  { label: 'New count', key: 'newCount' },
  { label: 'Reserved count', key: 'reservedCount' },
  { label: 'Consumed count', key: 'consumedCount' },
  { label: 'Digital count', key: 'digitalCount' }
]

const DATEOPTIONS: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit'
}

export const organizationLoader: LoaderFunction = async ({ params, request }) => {
  const { organizationId } = params

  const url = new URL(request.url)
  const month = url.searchParams.get(MONTH_SEARCH_PARAM)
  const year = url.searchParams.get(YEAR_SEARCH_PARAM)

  return await authenticatedFetchData(
    `${API_HOST}/v1.0/statistics/organization/${organizationId}?year=${year}&month=${month}`
  )
    .run()
    .then(statistics => ({ statistics }))
    .catch(error => {
      if (error.status !== 500) {
        logError(new Error(`Failed to fetch barcode organizations`), error)
      }

      return { error: 'Failed to fetch organization statistics' }
    })
}

export const OrganizationStatistics = () => {
  const { statistics: maybeStatistics, error } = useLoaderData() as { statistics?: any[]; error?: string }

  if (error) {
    return (
      <h1 className="alert alert-danger max-w-screen-sm mx-auto my-lg">
        Whoopsie! Failed fetch statistics. Please try again.
      </h1>
    )
  }

  const statistics = maybeStatistics as StatisticsType[]

  return (
    <>
      <div className="absolute top-[3.7rem] right-lg">
        <CSVLink
          data={statistics}
          headers={HEADERS}
          separator=";"
          filename={'tomra_statistics_' + new Date().toLocaleString('en-us', DATEOPTIONS) + '.csv'}
          className="btn"
        >
          Export
        </CSVLink>
      </div>

      <StatisticsList statistics={statistics} />
    </>
  )
}
