import { Dialog } from '../../common/Dialog'
import { SoapClientType } from '../../../types'
import React, { FunctionComponent, useEffect } from 'react'
import { API_HOST, authenticatedFetchData, logError } from '../../../lib'
import { ErrorIcon } from '../../icons'
import { useFetcher } from 'react-router-dom'
import type { ActionFunction } from 'react-router-dom'

export const showPasswordAction: ActionFunction = async ({ params }) => {
  const { userName } = params

  return authenticatedFetchData(`${API_HOST}/v1.0/soap-clients/client/${userName}`)
    .run()
    .catch(error => {
      if (error.status !== 500) {
        logError(new Error(`Failed to fetch password`), error)
      }

      return { error: 'Failed to fetch password' }
    })
}

type Props = { user?: SoapClientType; onClose: () => void }

export const UserDialog: FunctionComponent<Props> = ({ user, onClose }) => {
  useEffect(() => {
    const handleKeypress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    window.addEventListener('keyup', handleKeypress)
    return () => window.removeEventListener('keyup', handleKeypress)
  }, [onClose])

  const fetcher = useFetcher()

  return (
    <Dialog isShown={Boolean(user)}>
      <div className="card flex flex-col space-y-md p-lg min-w-screen-sm max-w-screen-sm">
        <h1 className="text-lg mb-md">User details</h1>

        <dl className="grid grid-cols-12 gap-md">
          <dt id="username" className="label col-span-3">
            Username
          </dt>
          <dd aria-labelledby="username" className="col-span-9">
            {user?.userName}
          </dd>
        </dl>

        <dl className="grid grid-cols-12 gap-md">
          <dt id="organization" className="label col-span-3">
            Organization
          </dt>
          <dd className="col-span-9" aria-labelledby="organization">
            {user?.organizationName}
          </dd>
        </dl>

        <div className="grid grid-cols-12 gap-md items-center">
          <div id="password" className="label col-span-3">
            Password
          </div>
          <div className="col-span-9">
            {fetcher.state === 'loading' ? (
              <div className="loadingSpinner" />
            ) : fetcher.data?.password ? (
              <span aria-labelledby="password">{fetcher.data?.password}</span>
            ) : fetcher.data?.error ? (
              <div className="flex space-x-sm">
                <ErrorIcon color="var(--colors-red)" width="1.5rem" height="1.5rem" />
                <span className="font-bold text-red">Failed to fetch password</span>
              </div>
            ) : (
              <fetcher.Form method="post" action={`${user?.userName}/show-password`}>
                <button className="btn btn-primary-dark">Show password</button>
              </fetcher.Form>
            )}
          </div>
        </div>

        <div className="flex justify-end">
          <button type="button" className="btn" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </Dialog>
  )
}
