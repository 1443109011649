import React from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { BarcodeSearch, barcodeSearchAction } from './components/pages/search/BarcodeSearch'
import { SerialSearch, serialSearchAction } from './components/pages/search/SerialSearch'
import { VoucherTimeline, voucherTimelineLoader } from './components/pages/voucherTimeline/VoucherTimeline'
import {
  statisticsLoader,
  StatisticsPage,
  statisticsSearchAction
} from './components/pages/organizationStatistics/StatisticsPage'
import { usersLoader, UsersPage } from './components/pages/users/UsersPage'
import { AppLayout } from './AppLayout'
import { SearchPage } from './components/pages/search/SearchPage'
import { SerialResults, serialResultsLoader } from './components/pages/search/SerialResults'
import { BarcodeResults, barcodeResultsLoader } from './components/pages/search/BarcodeResults'
import { ErrorElement } from './components/common/ErrorElement'
import {
  organizationLoader,
  OrganizationStatistics
} from './components/pages/organizationStatistics/OrganizationStatistics'
import { addUserAction } from './components/pages/users/AddNewUserDialog'
import { showPasswordAction } from './components/pages/users/UserDialog'

const appRoutes = [
  {
    id: 'root',
    element: <AppLayout />,
    children: [
      {
        path: 'search',
        element: <SearchPage />,
        children: [
          {
            path: 'barcode',
            element: <BarcodeSearch />,
            action: barcodeSearchAction,
            errorElement: <ErrorElement />,
            children: [
              {
                path: ':barcode',
                loader: barcodeResultsLoader,
                element: <BarcodeResults />
              }
            ]
          },
          {
            path: 'serial',
            element: <SerialSearch />,
            action: serialSearchAction,
            errorElement: <ErrorElement />,
            children: [
              {
                path: ':serialNumber',
                loader: serialResultsLoader,
                element: <SerialResults />
              }
            ]
          }
        ]
      },
      {
        id: 'statistics',
        path: 'statistics',
        loader: statisticsLoader,
        action: statisticsSearchAction,
        errorElement: <ErrorElement />,
        element: <StatisticsPage />,
        children: [
          {
            path: 'organization/:organizationId',
            loader: organizationLoader,
            element: <OrganizationStatistics />,
            errorElement: <ErrorElement />
          }
        ]
      },
      {
        path: 'users',
        loader: usersLoader,
        element: <UsersPage />,
        errorElement: <ErrorElement />,
        children: [
          {
            path: 'add-user',
            action: addUserAction
          },
          {
            path: ':userName/show-password',
            action: showPasswordAction
          }
        ]
      },
      {
        path: 'transactions/:barcode/:organizationId',
        loader: voucherTimelineLoader,
        element: <VoucherTimeline />,
        errorElement: <ErrorElement />
      },
      {
        path: '*',
        element: <Navigate to="/search/barcode" />
      }
    ]
  }
]

export const App = () => {
  return (
    <RouterProvider
      router={createBrowserRouter(appRoutes)}
      fallbackElement={
        <div className="centerFlex mt-lg">
          <div className="loadingSpinner" />
        </div>
      }
    />
  )
}
