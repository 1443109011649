import React from 'react'
import { useFetchers, useLoaderData, useParams, useSearchParams } from 'react-router-dom'
import type { LoaderFunction } from 'react-router-dom'
import { VoucherList } from '../../common/VoucherList'
import { API_HOST, authenticatedFetchData, logError } from '../../../lib'
import { CSVLink } from 'react-csv'
import { convertVoucherDomainObjectToReadable } from '../../../lib/convertVoucherDomainObjectToReadable'
import { VoucherType } from '../../../types'
import { FROM_DATE_SEARCH_PARAM, TO_DATE_SEARCH_PARAM } from '../../../constants'
import { updateSearchHistory } from '../../../lib/history'

const HEADERS = [
  { label: 'Barcode', key: 'barcode' },
  { label: 'Voucher state', key: 'voucherState' },
  { label: 'Rvm serial', key: 'rvmSerialNumber' },
  { label: 'Organization', key: 'organizationName' },
  { label: 'CS3 Start time', key: 'csTimestamp' },
  { label: 'CS3 Source', key: 'csSource' },
  { label: 'Last updated', key: 'transactionTimestamp' },
  { label: 'Last updated by', key: 'consumerId' },
  { label: 'Last updated by (sub)', key: 'consumerLocation' },
  { label: 'Refund amount', key: 'refundAmount' },
  { label: 'Is Digital', key: 'csDigital' }
]

const DATEOPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit'
}

export const serialResultsLoader: LoaderFunction = async ({ params, request }) => {
  const { serialNumber } = params

  await updateSearchHistory(request.url)

  const url = new URL(request.url)
  const fromMs = url.searchParams.get(FROM_DATE_SEARCH_PARAM)
  const toMs = url.searchParams.get(TO_DATE_SEARCH_PARAM)

  return await authenticatedFetchData(
    `${API_HOST}/v1.0/search/serial/${serialNumber}?fromDate=${fromMs}&toDate=${toMs}`
  )
    .run()
    .then(response => ({ vouchers: response }))
    .catch(error => {
      if (error.status !== 500) {
        logError(new Error(`Failed to fetch vouchers ${serialNumber}`), error)
      }

      return { error: 'Failed to fetch vouchers' }
    })
}

export const SerialResults = () => {
  const { vouchers: maybeVouchers, error } = useLoaderData() as { vouchers?: VoucherType[]; error: string }
  const { serialNumber } = useParams<{ [key: string]: string }>()
  const [searchParams] = useSearchParams()

  const fromMs = parseInt(searchParams.get(FROM_DATE_SEARCH_PARAM) as string)
  const toMs = parseInt(searchParams.get(TO_DATE_SEARCH_PARAM) as string)
  const fromDate = new Date(fromMs).toLocaleDateString()
  const toDate = new Date(toMs).toLocaleDateString()

  const [fetcher] = useFetchers()

  if (fetcher?.state === 'loading' || fetcher?.state === 'submitting') {
    return (
      <div className="centerAbsolute">
        <div className="loadingSpinner" />
      </div>
    )
  }

  if (error) {
    return (
      <div className="alert alert-danger mt-md max-w-screen-md mx-auto">
        Whoopsie! Failed to fetch vouchers. Please try again.
      </div>
    )
  }

  if (maybeVouchers?.length === 0) {
    return (
      <div className="alert alert-warning mt-md max-w-screen-md mx-auto">
        No vouchers found matching serial number {serialNumber} from {fromDate} to {toDate}
      </div>
    )
  }

  const vouchers = maybeVouchers as VoucherType[]
  const csvData = vouchers.map(convertVoucherDomainObjectToReadable)

  return (
    <VoucherList vouchers={vouchers}>
      <div className="w-full flex justify-between">
        <h2 className="text-lg">Serial {serialNumber}</h2>

        <CSVLink
          data={csvData}
          separator=";"
          headers={HEADERS}
          filename={'tomra_serialResults_' + new Date().toLocaleString('en-us', DATEOPTIONS) + '.csv'}
          className="btn mr-md"
        >
          Export
        </CSVLink>
      </div>
    </VoucherList>
  )
}
