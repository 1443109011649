import React, { useRef, useState } from 'react'
import { Receipt } from './Receipt'
import { exportComponentAsPNG } from 'react-component-export-image'
import { VoucherType } from '../../../types'
import { Dialog } from '../../common/Dialog'

type Props = {
  show: boolean
  voucher: VoucherType
  onClose: () => void
}

export const ReceiptDialog = ({ show, voucher, onClose }: Props) => {
  const receiptRef = useRef<HTMLDivElement>(null)
  const [isExporting, setIsExporting] = useState(false)
  const { barcode } = voucher

  const exportReceiptAsPNG = () => {
    setIsExporting(true)
    exportComponentAsPNG(receiptRef, {
      fileName: `receipt-${barcode}`,
      html2CanvasOptions: { scrollX: 0, scrollY: 0 }
    }).then(() => {
      setIsExporting(false)
      onClose()
    })
  }

  return (
    <Dialog isShown={show}>
      <div className="card flex flex-col w-min">
        <div className="m-sm border">
          <Receipt voucher={voucher} ref={receiptRef} />
        </div>
        <div className="flex flex-row m-sm">
          <button className="btn btn-primary-dark mr-sm" disabled={isExporting} onClick={() => exportReceiptAsPNG()}>
            Export receipt copy as image
          </button>
          <button className="btn" disabled={isExporting} onClick={() => onClose()}>
            Close
          </button>
        </div>
      </div>
    </Dialog>
  )
}
