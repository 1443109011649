import React from 'react'
import { useRouteError } from 'react-router-dom'
import { logError } from '../../lib'

export const ErrorElement = () => {
  const error = useRouteError() as any

  logError(new Error(error.data || error.message), error)

  return (
    <div className="max-w-screen-sm mx-auto alert alert-danger my-lg prose">
      <h3>Something went wrong</h3>
      <p>Our developers have been notified of the issue. Please try again.</p>
    </div>
  )
}
