import { VoucherType } from '../types'

export const convertVoucherDomainObjectToReadable = (voucher: VoucherType) => {
  return {
    ...voucher,
    barcode: `\t ${voucher.barcode}`,
    csTimestamp: new Date(voucher.csTimestamp).toLocaleString('default', {
      timeZone: voucher.locationTimezone || 'UTC'
    }),
    transactionTimestamp: new Date(voucher.transactionTimestamp).toLocaleString('default', {
      timeZone: voucher.locationTimezone || 'UTC'
    }),
    refundAmount: `\t ${parseFloat(voucher.refundAmount).toFixed(2)}`,
    csDigital: voucher.csDigital ? 'Yes' : 'No'
  }
}
