import React from 'react'
import { loggedInUserHasRole } from '../../../lib'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'

export const SearchPage = () => {
  const { pathname } = useLocation()

  return (
    <div className="max-w-screen-2xl w-full mt-lg mx-auto">
      {loggedInUserHasRole('VOUCHER_CONTROL_ADMIN_MACHINE_SEARCH') && (
        <div className="tabs max-w-screen-sm mx-auto">
          <Link to="barcode" className="relative tab">
            <span>
              <span className="relative z-[1]">Barcode</span>
              {pathname.includes('barcode') ? (
                <motion.span
                  layoutId="tabs"
                  className="absolute top-0 h-[3.4rem] left-[2px] right-0 rounded-l bg-[rgb(143_223_236)] z-0"
                />
              ) : null}
            </span>
          </Link>
          <Link to="serial" className="relative tab">
            <span>
              <span className="relative z-[1]">Serial Number</span>
              {pathname.includes('serial') ? (
                <motion.span
                  layoutId="tabs"
                  className="absolute top-0 rounded-r h-[3.4rem] left-[2px] right-0 bg-[rgb(143_223_236)] z-0"
                />
              ) : null}
            </span>
          </Link>
        </div>
      )}

      <Outlet />
    </div>
  )
}
