import React, { FunctionComponent, ReactNode } from 'react'
import { ArrowDownIcon } from '../icons'

declare global {
  type SortOrderType = 'descending' | 'ascending'
}

export const SortableTh: FunctionComponent<{
  fieldKey: string
  onSort: (order: SortOrderType, key: string) => void
  sortOrder: SortOrderType
  sortKey: string
  children: ReactNode
}> = ({ fieldKey, onSort, sortOrder, sortKey, children }) => {
  const content = (
    <button
      className="flex items-center justify-between w-full font-bold -ml-sm p-sm focus:rounded-half"
      onClick={() => onSort(sortKey === fieldKey && sortOrder === 'descending' ? 'ascending' : 'descending', fieldKey)}
    >
      <span className={sortKey === fieldKey ? 'underline' : ''}>{children}</span>
      {sortKey === fieldKey && (
        <ArrowDownIcon
          className={`transition ${sortOrder === 'ascending' ? 'rotate-180' : 'rotate-0'}`}
          aria-hidden="true"
          width="1rem"
          height="1rem"
        />
      )}
    </button>
  )

  return sortKey === fieldKey ? <th aria-sort={sortOrder}>{content}</th> : <th>{content}</th>
}
