import React, { useMemo, useState } from 'react'
import { SortableTh } from '../../common/SortableTh'
import { sortKeyAscending, sortKeyDescending } from '../../../lib/sort'
import { StatisticsType } from '../../../types'

type Props = {
  statistics: StatisticsType[]
}

export const StatisticsList = ({ statistics }: Props) => {
  const [sortKey, setSortKey] = useState('machineSerialNumber')
  const [sortOrder, setSortOrder] = useState<SortOrderType>('descending')

  const setSortedColumn = (order: SortOrderType, key: string) => {
    setSortOrder(order)
    setSortKey(key)
  }

  const sortedStatistics = useMemo(
    () => statistics.sort(sortOrder === 'ascending' ? sortKeyAscending(sortKey) : sortKeyDescending(sortKey)),
    [sortKey, sortOrder, statistics]
  )

  return (
    <table className="table">
      <thead>
        <tr>
          <SortableTh fieldKey="storeName" onSort={setSortedColumn} sortKey={sortKey} sortOrder={sortOrder}>
            Store name
          </SortableTh>
          <SortableTh fieldKey="machineSerialNumber" onSort={setSortedColumn} sortKey={sortKey} sortOrder={sortOrder}>
            RVM serial
          </SortableTh>
          <SortableTh fieldKey="newCount" onSort={setSortedColumn} sortKey={sortKey} sortOrder={sortOrder}>
            New count
          </SortableTh>
          <SortableTh fieldKey="reservedCount" onSort={setSortedColumn} sortKey={sortKey} sortOrder={sortOrder}>
            Reserved count
          </SortableTh>
          <SortableTh fieldKey="consumedCount" onSort={setSortedColumn} sortKey={sortKey} sortOrder={sortOrder}>
            Consumed count
          </SortableTh>
          <SortableTh fieldKey="digitalCount" onSort={setSortedColumn} sortKey={sortKey} sortOrder={sortOrder}>
            Digital count
          </SortableTh>
        </tr>
      </thead>
      <tbody>
        {sortedStatistics.map(rvmStatistic => {
          const { storeName, machineSerialNumber, newCount, reservedCount, consumedCount, digitalCount } = rvmStatistic

          return (
            <tr key={machineSerialNumber}>
              <td>{storeName}</td>
              <td>{machineSerialNumber}</td>
              <td>{newCount}</td>
              <td>{reservedCount}</td>
              <td>{consumedCount}</td>
              <td>{digitalCount}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
