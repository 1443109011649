import React, { useMemo, useState } from 'react'
import { UserDialog } from './UserDialog'
import { OrganizationType, SoapClientType } from '../../../types'
import { AddNewUserDialog } from './AddNewUserDialog'
import { API_HOST, authenticatedFetchData, logError, loggedInUserHasRole } from '../../../lib'
import { SortableTh } from '../../common/SortableTh'
import { sortKeyAscending, sortKeyDescending } from '../../../lib/sort'
import { useFetcher, useLoaderData } from 'react-router-dom'
import type { LoaderFunction } from 'react-router-dom'

export const usersLoader: LoaderFunction = () => {
  const fetchSoapClients = authenticatedFetchData(`${API_HOST}/v1.0/soap-clients`).run()
  const fetchSoapOrganizations = authenticatedFetchData(`${API_HOST}/v1.0/soap-clients/organization`).run() as Promise<
    OrganizationType[]
  >

  return Promise.all([fetchSoapClients, fetchSoapOrganizations])
    .then(([soapClients, organizations]) => ({
      soapClients,
      organizations: organizations.sort((a, b) => (a.name > b.name ? 1 : -1))
    }))
    .catch(error => {
      if (error.status !== 500) {
        logError(new Error(`Failed to soap clients`), error)
      }

      return { error: 'Failed to fetch soap clients' }
    })
}

export const UsersPage = () => {
  const { soapClients, error } = useLoaderData() as {
    soapClients?: SoapClientType[]
    error?: string
  }

  const [showAddNewUserDialog, setShowAddNewUserDialog] = useState(false)
  const [selectedUser, setSelectedUser] = useState<SoapClientType>()
  const [sortOrder, setSortOrder] = useState<SortOrderType>('descending')
  const [sortKey, setSortKey] = useState<string>('userName')

  const setSort = (order: SortOrderType, newSortKey: string) => {
    setSortKey(newSortKey)
    setSortOrder(order)
  }

  const sortedSoapClients = useMemo(
    () => soapClients?.sort(sortOrder === 'ascending' ? sortKeyAscending(sortKey) : sortKeyDescending(sortKey)) || [],
    [soapClients, sortKey, sortOrder]
  )

  const fetcher = useFetcher()

  if (!loggedInUserHasRole('VOUCHER_CONTROL_ADMIN_MANAGE_SOAP_CLIENTS')) {
    return <div className="alert alert-warning centerAbsolute">You&apos;re not authorized to view this page</div>
  }

  if (fetcher?.state === 'loading' || fetcher?.state === 'submitting') {
    return (
      <div className="centerAbsolute">
        <div className="loadingSpinner" />
      </div>
    )
  }

  if (error) {
    return (
      <div>
        <h1 className="text-lg">Users</h1>
        <div className="max-w-screen-sm mx-auto alert alert-danger my-lg">{error}</div>
      </div>
    )
  }

  return (
    <div className="max-w-screen-2xl mx-auto my-md">
      <UserDialog user={selectedUser} onClose={() => setSelectedUser(undefined)} />

      <AddNewUserDialog show={showAddNewUserDialog} onClose={() => setShowAddNewUserDialog(false)} />

      <div className="flex justify-between px-md mb-md">
        <h1 className="text-lg">Users</h1>

        <button className="btn btn-primary-dark" onClick={() => setShowAddNewUserDialog(true)}>
          New user
        </button>
      </div>

      {soapClients?.length === 0 ? (
        <div className="px-md">No users</div>
      ) : (
        <div className="card">
          <table className="table">
            <thead>
              <tr>
                <SortableTh fieldKey="userName" onSort={setSort} sortOrder={sortOrder} sortKey={sortKey}>
                  Username
                </SortableTh>
                <SortableTh fieldKey="organizationName" onSort={setSort} sortOrder={sortOrder} sortKey={sortKey}>
                  Organization name
                </SortableTh>
                <SortableTh fieldKey="organizationId" onSort={setSort} sortOrder={sortOrder} sortKey={sortKey}>
                  Organization Id
                </SortableTh>
              </tr>
            </thead>
            <tbody>
              {sortedSoapClients.map(user => (
                <tr
                  key={user.userName}
                  className="cursor-pointer hover:bg-grey-light"
                  onClick={() => setSelectedUser(user)}
                >
                  <td>{user.userName}</td>
                  <td>{user.organizationName}</td>
                  <td>{user.organizationId}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
