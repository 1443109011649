import React, { FunctionComponent } from 'react'

type Props = {
  small?: boolean
  isFirstRow: boolean
  isLastRow: boolean
}

export const Circle: FunctionComponent<Props> = ({ isFirstRow, isLastRow, small }) => {
  const minWidth = small ? 'min-w-[0.9375rem]' : 'min-w-[1.875rem]'
  const minHeight = small ? 'min-h-[0.9375rem]' : 'min-h-[1.875rem]'

  const margin = small ? 'm-[0.46875rem]' : 'm-0'
  const displayBefore = isLastRow ? 'before:hidden' : 'before:block'
  const displayAfter = isFirstRow ? 'after:hidden' : 'after:block'

  const before = `before::content-[''] ${displayBefore} before:absolute before:top-[100%] before:left-[50%] before:transform before:-translate-x-[50%] before:h-[4.375rem] before:w-[0.0625rem] before:bg-blue before:z-[0]`
  const after = `after:content-[''] ${displayAfter} after:absolute after:bottom-[100%] after:left-[50%] after:transform after:-translate-x-[50%] after:h-[5.375rem] after:w-[0.0625rem] after:bg-blue after:z-[0]`

  return <div className={`relative ${minWidth} ${minHeight} ${margin} rounded bg-blue z-[1] ${after} ${before}`} />
}
