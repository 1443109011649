import React, { useState } from 'react'
import { useLoaderData, useNavigate, useParams } from 'react-router-dom'
import type { LoaderFunction } from 'react-router-dom'
import RequestRow from './rows/RequestRow'
import StateRow from './rows/StateRow'
import { DateWithUTCTooltip } from '../../common'
import { logError, API_HOST, authenticatedFetchData, loggedInUserHasRole } from '../../../lib'
import { ReceiptDialog } from '../receipt/ReceiptDialog'
import { VoucherTimelineType, VoucherType } from '../../../types'

export const voucherTimelineLoader: LoaderFunction = ({ params }) => {
  const { barcode, organizationId } = params
  const url = `${API_HOST}/v1.0/search/timeline?barcode=${barcode}&organization-id=${organizationId}`

  return authenticatedFetchData(url)
    .run()
    .catch(error => {
      if (error.status !== 500) {
        logError(new Error(`Failed to fetch voucher timeline`), error)
      }

      return { error: 'Failed to fetch voucher timeline' }
    })
}

export const VoucherTimeline = () => {
  const {
    timeline: maybeTimeline,
    voucher: maybeVoucher,
    error
  } = useLoaderData() as {
    voucher?: VoucherType
    timeline?: VoucherTimelineType[]
    error?: string
  }

  const [showExportReceiptDialog, setShowExportReceiptDialog] = useState(false)
  const { barcode: barcodeParam, organizationId: organizationIdParam } = useParams()
  const [barcodeSearchValue, setBarcodeSearchValue] = useState('')
  const navigate = useNavigate()

  if (error) {
    return (
      <h1 className="alert alert-danger centerAbsolute">Whoopsie! Failed fetch voucher timeline. Please try again.</h1>
    )
  }

  if (maybeTimeline?.length === 0) {
    return (
      <h1 className="alert alert-warning centerAbsolute">
        No entries found matching barcode &quot;{barcodeParam}&quot; and organization ID &quot;{organizationIdParam}
        &quot;
      </h1>
    )
  }

  const voucher = maybeVoucher as VoucherType
  const timeline = maybeTimeline as VoucherTimelineType[]

  const {
    barcode,
    organizationName,
    transactionTimestamp,
    voucherState,
    consumerId,
    consumerLocation,
    refundAmount,
    rvmSerialNumber,
    csTimestamp,
    csEndTimestamp,
    csSource,
    csDigital,
    locationTimezone,
    locationName
  } = voucher

  const canExportReceipt =
    loggedInUserHasRole('VOUCHER_CONTROL_ADMIN_EXPORT_RECEIPT') &&
    (voucherState === 'NEW' || voucherState === 'RESERVED')

  return (
    <div className="max-w-screen-2xl mx-auto p-lg">
      <ReceiptDialog
        show={Boolean(canExportReceipt && showExportReceiptDialog)}
        voucher={voucher}
        onClose={() => setShowExportReceiptDialog(false)}
      />

      <h1 className="text-xl mb-md">Voucher</h1>

      <div className="flex items-center space-x-md">
        <input
          type="text"
          name="barcode"
          value={barcodeSearchValue}
          onChange={({ target }) => setBarcodeSearchValue(target.value)}
          aria-label="Search by barcode"
          placeholder="Search by barcode"
          className="p-md text-lg w-3/4"
        />

        <button
          type="button"
          className="btn btn-primary-dark w-1/4"
          onClick={() => navigate(`/search/barcode/${barcodeSearchValue}`)}
        >
          Search
        </button>
      </div>

      <div className="flex flex-wrap items-start mt-lg">
        <div className="card flex flex-col p-lg mr-lg flex-auto space-y-md">
          <h2 className="text-xl mb-md">Details</h2>

          <dl>
            <dt id="barcode" className="block text-storm">
              Barcode
            </dt>
            <dd aria-labelledby="barcode">{barcode}</dd>
          </dl>
          <dl>
            <dt id="organizationName" className="block text-storm">
              Organization
            </dt>
            <dd aria-labelledby="organizationName">{organizationName}</dd>
          </dl>
          <dl>
            <dt id="refund" className="block text-storm">
              Refund
            </dt>
            <dd aria-labelledby="refund">{parseFloat(refundAmount).toFixed(2)}</dd>
          </dl>
          <dl>
            <dt id="rvmSerial" className="block text-storm">
              RVM Serial
            </dt>
            <dd aria-labelledby="rvmSerial">{rvmSerialNumber}</dd>
          </dl>
          <dl>
            <dt id="rvmLocation" className="block text-storm">
              RVM Location
            </dt>
            <dd aria-labelledby="rvmLocation">{locationName}</dd>
          </dl>
          <dl>
            <dt id="state" className="block text-storm">
              State
            </dt>
            <dd aria-labelledby="state">{voucherState}</dd>
          </dl>
          <dl>
            <dt id="lastUpdated" className="block text-storm">
              Last Updated
            </dt>
            <dd aria-labelledby="lastUpdated">
              <DateWithUTCTooltip date={transactionTimestamp} timeZone={locationTimezone} />
            </dd>
          </dl>
          <dl>
            <dt id="consumerID" className="block text-storm">
              Consumer ID
            </dt>
            <dd aria-labelledby="consumerID">{consumerId}</dd>
          </dl>
          <dl>
            <dt id="consumerLocation" className="block text-storm">
              Consumer Location
            </dt>
            <dd aria-labelledby="consumerLocation">{consumerLocation}</dd>
          </dl>
          <dl>
            <dt id="source" className="block text-storm">
              Source
            </dt>
            <dd aria-labelledby="source">{csSource}</dd>
          </dl>
          <dl>
            <dt id="digital" className="block text-storm">
              Digital
            </dt>
            <dd aria-labelledby="digital">{String(csDigital)}</dd>
          </dl>
          <dl>
            <dt id="cs3StartTime" className="block text-storm">
              CS3 Start time
            </dt>
            <dd aria-labelledby="cs3StartTime">
              <DateWithUTCTooltip date={csTimestamp} timeZone={locationTimezone} />
            </dd>
          </dl>
          <dl>
            <dt id="cs3EndTime" className="block text-storm">
              CS3 End time
            </dt>
            <dd aria-labelledby="cs3EndTime">
              {csEndTimestamp ? <DateWithUTCTooltip date={csEndTimestamp} timeZone={locationTimezone} /> : 'N/A'}
            </dd>
          </dl>

          {canExportReceipt && (
            <button className="btn" onClick={() => setShowExportReceiptDialog(true)}>
              Export receipt
            </button>
          )}
        </div>

        <div className="card p-xl relative flex flex-col flex-auto">
          <h2 className="text-xl mb-md">Timeline</h2>

          {timeline.map((entry, index) =>
            entry.eventType === 'REQUEST' ? (
              <RequestRow
                key={`${entry}-${index}`}
                entry={entry}
                isFirstRow={index === 0}
                isLastRow={index === timeline.length - 1}
                locationTimezone={voucher.locationTimezone}
              />
            ) : (
              <StateRow
                key={`${entry}-${index}`}
                entry={entry}
                isFirstRow={index === 0}
                isLastRow={index === timeline.length - 1}
                locationTimezone={voucher.locationTimezone}
              />
            )
          )}
        </div>
      </div>
    </div>
  )
}
