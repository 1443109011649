import React, { ForwardedRef } from 'react'
// @ts-ignore
import ReactBarcode from 'react-barcode'
import { organizationLogoMap } from './organizationLogoMap'
import { VoucherType } from '../../../types'
import { Images } from '../../../images'

// eslint-disable-next-line react/display-name
export const Receipt = React.forwardRef(({ voucher }: { voucher: VoucherType }, ref: ForwardedRef<HTMLDivElement>) => {
  const { organizationName, refundAmount, barcode, csTimestamp, locationTimezone } = voucher
  const organizationLogo = organizationLogoMap[organizationName.toLowerCase()] || organizationLogoMap.default
  return (
    <div className="bg-white w-min" ref={ref}>
      <div className="p-sm text-center items-center">
        <img className="m-auto" src={organizationLogo} alt={`${organizationName} logo`} />
      </div>
      <div className="mb-md">
        <div className="text-right text-lg bg-black text-white p-sm">
          <b>${parseFloat(refundAmount).toFixed(2)}</b>
        </div>
        <div className="m-md">
          <ReactBarcode value={barcode} />
        </div>
      </div>
      <div className="p-sm">
        <div className="flex p-sm">
          <img src={Images.receiptFooter} alt="Footer for the receipt" />
        </div>
      </div>
      <div className="p-sm">
        <p>
          <em>This is a receipt copy</em>
        </p>
        <p>
          <em>Issued: {new Date(csTimestamp).toLocaleString('default', { timeZone: locationTimezone || 'UTC' })}</em>
        </p>
      </div>
    </div>
  )
})
