import React, { useState } from 'react'
import { SearchHistory, SearchHistoryButton } from '../../common/SearchHistory'
import { loggedInUserHasRole } from '../../../lib'
import { Outlet, redirect, useFetcher, useParams, useSearchParams } from 'react-router-dom'
import type { ActionFunction } from 'react-router-dom'
import { endOfDay, format, startOfDay, subWeeks } from 'date-fns'
import { FROM_DATE_SEARCH_PARAM, TO_DATE_SEARCH_PARAM } from '../../../constants'

export const serialSearchAction: ActionFunction = async ({ request }) => {
  const formData = await request.formData()
  const { serial, fromDate, toDate } = Object.fromEntries(formData)

  const fromDateInMs = startOfDay(new Date(fromDate as string)).getTime()
  const toDateInMs = endOfDay(new Date(toDate as string)).getTime()

  return redirect(`${serial}?${FROM_DATE_SEARCH_PARAM}=${fromDateInMs}&${TO_DATE_SEARCH_PARAM}=${toDateInMs}`)
}

export const SerialSearch = () => {
  const [searchParams] = useSearchParams()
  const { serialNumber } = useParams()
  const [historyVisible, setHistoryVisible] = useState(false)

  const today = new Date()
  const oneWeekAgo = subWeeks(today, 1)

  const fromMs = parseInt(searchParams.get(FROM_DATE_SEARCH_PARAM) as string)
  const toMs = parseInt(searchParams.get(TO_DATE_SEARCH_PARAM) as string)
  const fromDate = fromMs ? new Date(fromMs) : oneWeekAgo
  const toDate = toMs ? new Date(toMs) : today

  const fetcher = useFetcher()

  if (!loggedInUserHasRole('VOUCHER_CONTROL_ADMIN_MACHINE_SEARCH')) {
    return <div className="alert alert-warning centerAbsolute">You&apos;re not authorized to view this page</div>
  }

  return (
    <>
      <div className="max-w-screen-sm mx-auto">
        <h1 className="sr-only">Search by RVM serial number</h1>

        <fetcher.Form method="post" action="">
          <div className="flex items-center justify-between mt-lg relative">
            <div>
              <label htmlFor="fromDate" className="label">
                From
              </label>
              <input id="fromDate" type="date" name="fromDate" defaultValue={format(fromDate, 'yyyy-MM-dd')} required />
            </div>

            <div className="absolute mt-[1.6rem] translate-y-1/2 w-full h-[1px] bg-grey -z-[1]" />

            <div>
              <label htmlFor="toDate" className="label">
                To
              </label>
              <input
                id="toDate"
                type="date"
                name="toDate"
                min={format(fromDate, 'yyyy-MM-dd')}
                defaultValue={format(toDate, 'yyyy-MM-dd')}
                required
              />
            </div>
          </div>

          <div className="relative pt-lg flex space-x-md">
            <SearchHistoryButton active={historyVisible} onClick={() => setHistoryVisible(!historyVisible)} />

            <div className="flex-1">
              <input
                type="text"
                name="serial"
                placeholder="Search by serial"
                aria-label="Search by RVM serial number"
                className="p-md text-lg"
                defaultValue={serialNumber}
                required
              />
            </div>

            <button type="submit" className="btn btn-primary-dark">
              Search
            </button>
          </div>
        </fetcher.Form>

        <SearchHistory show={historyVisible} close={() => setHistoryVisible(false)} />
      </div>

      <Outlet />
    </>
  )
}
