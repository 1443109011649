import React, { useState } from 'react'

export const DateWithUTCTooltip = ({ date, timeZone }: { date: string; timeZone?: string }) => {
  const [showToolTip, setShowTooltip] = useState(false)

  const toolTipMessage = timeZone
    ? new Date(date).toLocaleString('default', { timeZone: 'UTC' }) + ' in UTC'
    : "Can't determine timezone of RVM. Therefore shown in UTC"

  return date ? (
    <div
      className="relative group"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <div>
        {new Date(date).toLocaleString('default', { timeZone: timeZone || 'UTC' })}
        {!timeZone && <small className="text-red text-sm">Shown in UTC</small>}
      </div>

      {showToolTip && (
        <div className="absolute bg-midnight rounded-half text-white shadow-md left-0 -top-[40%] p-sm">
          {toolTipMessage}
        </div>
      )}
    </div>
  ) : (
    <span>-</span>
  )
}
