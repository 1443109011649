import tomraArrow from './misc/tomraArrow.png'
import tomraArrowBlack from './misc/tomraArrowBlack.png'
import tomraLogo from './misc/tomraPngLogo.png'
import receiptFooter from './misc/receiptfooter.png'
// @ts-ignore
import colesLogo from './organizations/coles.bmp'
import temporaryEuroshopVoucherLogo from './organizations/temporaryEuroshopVoucherLogo.svg'
// @ts-ignore
import woolworthsLogo from './organizations/woolworths.bmp'

export const Images = {
  tomraArrow,
  tomraArrowBlack,
  tomraLogo,
  receiptFooter,
  colesLogo,
  temporaryEuroshopVoucherLogo,
  woolworthsLogo
}
