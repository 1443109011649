import React from 'react'
import ReactDOM from 'react-dom'
import { AnimatePresence, motion } from 'framer-motion'

type Props = {
  isShown: boolean
  children: React.ReactNode
}

export const Dialog = ({ isShown, children }: Props) => {
  return ReactDOM.createPortal(
    <AnimatePresence mode="wait">
      {isShown && (
        <motion.div className="dialog-overlay" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <motion.div
            initial={{ opacity: 0, translateY: '-20%' }}
            animate={{ opacity: 1, translateY: '0%' }}
            exit={{ opacity: 0, translateY: '-20%' }}
            className="dialog-content"
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.getElementById('root') as HTMLDivElement
  )
}
