/* eslint no-console: 0 */

const isLocalhost = !!window.location.href.match(/:\d{4}/g)

export function logError(error: Error, cause?: Error | null, metadata?: any) {
  if (cause) {
    error.message += `, Caused by: ${cause.toString()}`
    error.stack += '\nCaused by: ' + cause.stack
  }

  // @ts-ignore
  const context = { metadata, explicitlyLogged: true }

  // @ts-ignore
  if (!isLocalhost && window.DD_RUM) {
    // @ts-ignore
    window.DD_RUM.addError(error, context)
  } else {
    console.error(error)
  }
}
