import React from 'react'
import { createRoot } from 'react-dom/client'
import { authentication } from './lib'
import { App } from './App'
import '@tomra/design-system/src/config/tailwind.config.css'

const container = document.getElementById('root')
const root = createRoot(container as HTMLDivElement)

const Index = () => (
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

authentication.init().then(
  () => root.render(<Index />),
  () => authentication.login()
)
