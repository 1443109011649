import React from 'react'
import { Circle } from './Circle'
import { DateWithUTCTooltip } from '../../../common'
import { VoucherTimelineType } from '../../../../types'

type Props = {
  entry: VoucherTimelineType
  locationTimezone?: string
  isFirstRow: boolean
  isLastRow: boolean
}

export const RequestRow = ({ entry, locationTimezone, isFirstRow, isLastRow }: Props) => {
  const {
    requestType,
    consumerId,
    success,
    responseMessage,
    responseCode,
    timestamp,
    internalResponse,
    durationMillis,
    clientId
  } = entry

  const readableRequestMessageMap: { [key: string]: string } = {
    VOUCHER_RESERVED: 'Reserve',
    VOUCHER_CONSUMED: 'Consume',
    ENABLED_FOR_STORE_PAYOUT: 'Enable store payout',
    SET_TO_DIGITAL_PAYOUT: 'Disable store payout',
    DIGITAL_VOUCHER_CONSUMED_NOTIFIED: 'myTOMRA notification',
    UNKNOWN: 'Unknown'
  }

  const userIdMessage = `\n${clientId ? `ClientID: ${clientId}` : `ConsumerId: ${consumerId}`}`
  const readableRequestMessage = readableRequestMessageMap[requestType || 'UNKNOWN']
  const toolTipMessage = `Internal response: ${internalResponse} (${durationMillis} ms) \n${userIdMessage}`

  return (
    <div className="flex items-center py-md">
      <div className="mr-lg w-[9.375rem]">
        <DateWithUTCTooltip date={timestamp} timeZone={locationTimezone} />
      </div>

      <Circle isFirstRow={isFirstRow} isLastRow={isLastRow} small />

      <div className={`relative group ml-lg p-md border rounded-half ${success ? 'border-green' : 'border-red'}`}>
        <span className="absolute invisible group-hover:visible bg-midnight p-sm text-white shadow-md rounded-half left-0 -top-[40%]">
          {toolTipMessage}
        </span>
        <div className="truncateText flex flex-col">
          <div className="flex items-center">
            <b className="mr-xs">{readableRequestMessage}</b>
            <span>request from consumer ID</span>
            <b className="ml-xs">{consumerId}</b>
          </div>

          <span className="mx-xs">Return code: {responseCode}</span>
          <span className="mx-xs">Return message: {responseMessage}</span>
        </div>
      </div>
    </div>
  )
}

export default RequestRow
